const runners = [
    {
        "_id": 3,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:49:23.039Z",
        "officialtime": "00:49:02",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 1,
        "runner1": "Annie Crona Österström ",
        "runner2": "Per  Österström "
      },
      {
        "_id": 4,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:49:28.014Z",
        "officialtime": "00:49:07",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 1,
        "runner1": "Patrik Lundblad",
        "runner2": "Tina Lundblad"
      },
      {
        "_id": 5,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:43:53.854Z",
        "officialtime": "00:43:33",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 1,
        "runner1": "Tess Wittström",
        "runner2": "Niklas Wittström"
      },
      {
        "_id": 6,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:49:29.333Z",
        "officialtime": "00:49:09",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 1,
        "runner1": "Sonny  Antonsson",
        "runner2": "Andreas Granholm"
      },
      {
        "_id": 7,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:44:41.784Z",
        "officialtime": "00:44:21",
        "race": "lång",
        "runnersclass": "dam",
        "startgroup": 1,
        "runner1": "Jenny Kjellsdotter",
        "runner2": "Mariella Bewick"
      },
      {
        "_id": 8,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:45:23.175Z",
        "officialtime": "00:45:02",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 1,
        "runner1": "Johan Helin",
        "runner2": "Mikael Hjörnhede"
      },
      {
        "_id": 9,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:56:55.494Z",
        "officialtime": "00:56:35",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 1,
        "runner1": "Mia Enmark ",
        "runner2": "Anton  Enmark "
      },
      {
        "_id": 10,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:41:22.820Z",
        "officialtime": "00:41:02",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 1,
        "runner1": "Johan Larsson",
        "runner2": "Bo  Johansson"
      },
      {
        "_id": 11,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:55:26.218Z",
        "officialtime": "00:53:31",
        "race": "lång",
        "runnersclass": "dam",
        "startgroup": 2,
        "runner1": "Ingrid  Englund ",
        "runner2": "Katarina  Vallström "
      },
      {
        "_id": 12,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:48:42.526Z",
        "officialtime": "00:46:48",
        "race": "lång",
        "runnersclass": "dam",
        "startgroup": 2,
        "runner1": "Lisa Wallin",
        "runner2": "Johanna Skoglund"
      },
      {
        "_id": 13,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:45:40.411Z",
        "officialtime": "00:43:46",
        "race": "lång",
        "runnersclass": "dam",
        "startgroup": 2,
        "runner1": "Emma Mäsak",
        "runner2": "Andrea Segerlind "
      },
      {
        "_id": 14,
        "checkin": false,
        "finished": false,
        "finishtime": null,
        "officialtime": null,
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 2,
        "runner1": "Ida Addinsall",
        "runner2": "Ulysse Björk Jortay"
      },
      {
        "_id": 15,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:44:22.103Z",
        "officialtime": "00:42:27",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 2,
        "runner1": "Karin Jones",
        "runner2": "Mathias Håman"
      },
      {
        "_id": 16,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:45:43.531Z",
        "officialtime": "00:43:49",
        "race": "lång",
        "runnersclass": "dam",
        "startgroup": 2,
        "runner1": "Malin Storm",
        "runner2": "Ella Storm"
      },
      {
        "_id": 17,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:52:47.690Z",
        "officialtime": "00:50:53",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 2,
        "runner1": "Gunnar Wide",
        "runner2": "Thea Johansson"
      },
      {
        "_id": 18,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:42:21.027Z",
        "officialtime": "00:40:26",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 2,
        "runner1": "Tobias Westling",
        "runner2": "Anna Krona Westling "
      },
      {
        "_id": 19,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:44:55.316Z",
        "officialtime": "00:43:00",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 2,
        "runner1": "Kristian Heikkinen ",
        "runner2": "Dick Andersson "
      },
      {
        "_id": 20,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:57:45.068Z",
        "officialtime": "00:55:50",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 2,
        "runner1": "Fredrik Asztely",
        "runner2": "Magnus Rene"
      },
      {
        "_id": 21,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:02:19.047Z",
        "officialtime": "00:58:42",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 3,
        "runner1": "Maria Lindborg",
        "runner2": "Anders Lindborg"
      },
      {
        "_id": 22,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:46:00.228Z",
        "officialtime": "00:42:23",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 3,
        "runner1": "Henrik  Molin",
        "runner2": "Jonathan Fager"
      },
      {
        "_id": 23,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:07:29.656Z",
        "officialtime": "01:03:52",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 3,
        "runner1": "Clas Malmeström",
        "runner2": "Elis  Malmeström "
      },
      {
        "_id": 24,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:57:22.097Z",
        "officialtime": "00:53:45",
        "race": "lång",
        "runnersclass": "dam",
        "startgroup": 3,
        "runner1": "Anna Hanse",
        "runner2": "Lisa Hanse"
      },
      {
        "_id": 25,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:44:53.367Z",
        "officialtime": "00:41:16",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 3,
        "runner1": "Melker Kyllerman",
        "runner2": "Alex Krook, Emil Storm"
      },
      {
        "_id": 26,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:55:53.554Z",
        "officialtime": "00:52:16",
        "race": "lång",
        "runnersclass": "dam",
        "startgroup": 3,
        "runner1": "Maria  Rene",
        "runner2": "Kristina Mjörnell"
      },
      {
        "_id": 27,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:00:03.770Z",
        "officialtime": "00:56:26",
        "race": "lång",
        "runnersclass": "dam",
        "startgroup": 3,
        "runner1": "Emma Krantz ",
        "runner2": "Maria Sandgren"
      },
      {
        "_id": 28,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:04:32.746Z",
        "officialtime": "01:00:55",
        "race": "lång",
        "runnersclass": "dam",
        "startgroup": 3,
        "runner1": "Ulrika Hanse",
        "runner2": "Gunilla  Sondén"
      },
      {
        "_id": 29,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:10:23.939Z",
        "officialtime": "01:06:46",
        "race": "lång",
        "runnersclass": "dam",
        "startgroup": 3,
        "runner1": "Anki Wennergren ",
        "runner2": "Marie  Jarefjäll"
      },
      {
        "_id": 30,
        "checkin": false,
        "finished": false,
        "finishtime": null,
        "officialtime": null,
        "race": "lång",
        "runnersclass": "dam",
        "startgroup": 3,
        "runner1": "Lisa Wessling",
        "runner2": "Kristina Elfving"
      },
      {
        "_id": 31,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:51:15.679Z",
        "officialtime": "00:46:03",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 4,
        "runner1": "Hanna Kolberg",
        "runner2": "Viktor Tendahl"
      },
      {
        "_id": 32,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:57:39.878Z",
        "officialtime": "00:52:27",
        "race": "lång",
        "runnersclass": "dam",
        "startgroup": 4,
        "runner1": "Jenny Helmbro ",
        "runner2": "Therese  Stomberg"
      },
      {
        "_id": 33,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:56:23.998Z",
        "officialtime": "00:51:11",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 4,
        "runner1": "Åsa Leffler",
        "runner2": "Mattias Bengtsson"
      },
      {
        "_id": 34,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:53:45.244Z",
        "officialtime": "00:48:33",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 4,
        "runner1": "Ludvig  Högberg ",
        "runner2": "Teodor Eckerstein "
      },
      {
        "_id": 35,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:55:00.819Z",
        "officialtime": "00:49:48",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 4,
        "runner1": "Samuel Eckerstein ",
        "runner2": "Emil Eckerstein "
      },
      {
        "_id": 36,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:53:15.215Z",
        "officialtime": "00:48:03",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 4,
        "runner1": "Casper Gerdén",
        "runner2": "Andreas Gerdén, Oliver Englund"
      },
      {
        "_id": 37,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:43:34.212Z",
        "officialtime": "00:38:22",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 4,
        "runner1": "Ulrik Henriksson",
        "runner2": "Stina-Cajsa Berlin"
      },
      {
        "_id": 38,
        "checkin": false,
        "finished": false,
        "finishtime": null,
        "officialtime": null,
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 4,
        "runner1": "Jens Hjerling Leffler",
        "runner2": "Ekaterina Leffler"
      },
      {
        "_id": 39,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:54:11.363Z",
        "officialtime": "00:48:59",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 4,
        "runner1": "Mats Tullberg",
        "runner2": "Arvid Tullberg Sundholm "
      },
      {
        "_id": 40,
        "checkin": false,
        "finished": false,
        "finishtime": null,
        "officialtime": null,
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 4,
        "runner1": "Magnus  Skogsberg ",
        "runner2": "Per Skogsberg "
      },
      {
        "_id": 41,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:01:01.415Z",
        "officialtime": "00:54:11",
        "race": "lång",
        "runnersclass": "dam",
        "startgroup": 5,
        "runner1": "Maya Kyllerman",
        "runner2": "Lisa Ahlenius"
      },
      {
        "_id": 42,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:55:06.596Z",
        "officialtime": "00:48:16",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 5,
        "runner1": "Niclas Karlsson",
        "runner2": "Andreas Johansson"
      },
      {
        "_id": 43,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:57:47.789Z",
        "officialtime": "00:50:57",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 5,
        "runner1": "Magnus Hellekant",
        "runner2": "Josefin Hellekant"
      },
      {
        "_id": 44,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:50:26.804Z",
        "officialtime": "00:43:36",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 5,
        "runner1": "Johan Söderlund",
        "runner2": "Sebastian  Arneland "
      },
      {
        "_id": 45,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:57:44.870Z",
        "officialtime": "00:50:54",
        "race": "lång",
        "runnersclass": "dam",
        "startgroup": 5,
        "runner1": "Olivia Krook",
        "runner2": "Sofia Heymann"
      },
      {
        "_id": 46,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:15:19.350Z",
        "officialtime": "01:08:28",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 5,
        "runner1": "Marie Eriksson Johansson",
        "runner2": "Samuel Karlsson "
      },
      {
        "_id": 47,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:45:19.170Z",
        "officialtime": "00:38:28",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 5,
        "runner1": "olof bewick",
        "runner2": "julian håkansson"
      },
      {
        "_id": 48,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:10:19.131Z",
        "officialtime": "01:03:28",
        "race": "lång",
        "runnersclass": "dam",
        "startgroup": 5,
        "runner1": "Susanne Persson",
        "runner2": "Tove Thuresson Skoglund"
      },
      {
        "_id": 49,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:57:59.040Z",
        "officialtime": "00:51:08",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 5,
        "runner1": "Frida Magnusson",
        "runner2": "Sebastian Magnusson"
      },
      {
        "_id": 50,
        "checkin": false,
        "finished": false,
        "finishtime": null,
        "officialtime": null,
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 5,
        "runner1": "Anna Schlosser",
        "runner2": "Johan  Johansson"
      },
      {
        "_id": 51,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:58:19.267Z",
        "officialtime": "00:49:53",
        "race": "lång",
        "runnersclass": "dam",
        "startgroup": 6,
        "runner1": "Sofia Wallin",
        "runner2": "Emma Wallin"
      },
      {
        "_id": 52,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:57:09.059Z",
        "officialtime": "00:48:43",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 6,
        "runner1": "Karin Bäck",
        "runner2": "John Bäck"
      },
      {
        "_id": 53,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:57:25.163Z",
        "officialtime": "00:48:59",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 6,
        "runner1": "Rymon Koda",
        "runner2": "David Gustavsson"
      },
      {
        "_id": 54,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:54:26.282Z",
        "officialtime": "00:46:00",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 6,
        "runner1": "Simon Andersson",
        "runner2": "Viktor Nyberg"
      },
      {
        "_id": 55,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:56:58.235Z",
        "officialtime": "00:48:32",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 6,
        "runner1": "Ida Sjöberg",
        "runner2": "Marcus  Sjöberg"
      },
      {
        "_id": 56,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:57:19.365Z",
        "officialtime": "00:48:53",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 6,
        "runner1": "Ken Hymnelius",
        "runner2": "Rebecca Hymnelius"
      },
      {
        "_id": 57,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:04:37.640Z",
        "officialtime": "00:56:11",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 6,
        "runner1": "Tova  Geremyr ",
        "runner2": "Filip Holmqvist "
      },
      {
        "_id": 58,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:58:33.143Z",
        "officialtime": "00:50:07",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 6,
        "runner1": "Mattias Larsson",
        "runner2": "Jakob  Steinwall"
      },
      {
        "_id": 59,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:01:38.315Z",
        "officialtime": "00:53:12",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 6,
        "runner1": "Kristian Peders",
        "runner2": "Lisa Ödman"
      },
      {
        "_id": 60,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:05:02.276Z",
        "officialtime": "00:56:36",
        "race": "lång",
        "runnersclass": "dam",
        "startgroup": 6,
        "runner1": "Ida Benkel",
        "runner2": "Lina Benkel"
      },
      {
        "_id": 61,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:47:38.169Z",
        "officialtime": "00:37:33",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 7,
        "runner1": "Jennie Ohlsson",
        "runner2": "Eric Ohlsson"
      },
      {
        "_id": 62,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:06:58.822Z",
        "officialtime": "00:56:53",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 7,
        "runner1": "Elsa Ljungquist",
        "runner2": "Olle Ljungquist"
      },
      {
        "_id": 63,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:56:46.786Z",
        "officialtime": "00:46:41",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 7,
        "runner1": "Johan Levin",
        "runner2": "Simon Corell"
      },
      {
        "_id": 64,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:55:47.778Z",
        "officialtime": "00:45:42",
        "race": "lång",
        "runnersclass": "dam",
        "startgroup": 7,
        "runner1": "Hanna Niklasson ",
        "runner2": "Malin Tengelin"
      },
      {
        "_id": 65,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:04:50.207Z",
        "officialtime": "00:54:45",
        "race": "lång",
        "runnersclass": "dam",
        "startgroup": 7,
        "runner1": "Monika Daleng",
        "runner2": "Veronica  Stenberg Gustafsson "
      },
      {
        "_id": 66,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:01:09.478Z",
        "officialtime": "00:51:04",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 7,
        "runner1": "Frida  Nyberg",
        "runner2": "Roger Nyberg"
      },
      {
        "_id": 67,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:53:57.411Z",
        "officialtime": "00:43:52",
        "race": "lång",
        "runnersclass": "dam",
        "startgroup": 7,
        "runner1": "Maria Hjortstam Möller",
        "runner2": "Josefine  Hult Fast"
      },
      {
        "_id": 68,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:02:33.659Z",
        "officialtime": "00:52:28",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 7,
        "runner1": "Martin  Svahn ",
        "runner2": "Anton  Svahn "
      },
      {
        "_id": 69,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:00:55.426Z",
        "officialtime": "00:50:50",
        "race": "lång",
        "runnersclass": "dam",
        "startgroup": 7,
        "runner1": "Gun Rembeck",
        "runner2": "Anna-Karin Relfsson"
      },
      {
        "_id": 70,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:58:40.616Z",
        "officialtime": "00:48:35",
        "race": "lång",
        "runnersclass": "dam",
        "startgroup": 7,
        "runner1": "Maria Lembäck",
        "runner2": "Ebba  Birging"
      },
      {
        "_id": 71,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:01:10.998Z",
        "officialtime": "00:49:25",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 8,
        "runner1": "Joakim  Olsson",
        "runner2": "Alexander Kurtsson"
      },
      {
        "_id": 72,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:53:49.508Z",
        "officialtime": "00:42:04",
        "race": "lång",
        "runnersclass": "dam",
        "startgroup": 8,
        "runner1": "Alice Olsson",
        "runner2": "Leontien Herweijer "
      },
      {
        "_id": 73,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:04:48.285Z",
        "officialtime": "00:53:03",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 8,
        "runner1": "Ingela  Andersson",
        "runner2": "Matti Westman"
      },
      {
        "_id": 74,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:59:29.057Z",
        "officialtime": "00:47:43",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 8,
        "runner1": "Richard Hallgren",
        "runner2": "Vanda Westman"
      },
      {
        "_id": 75,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:06:41.168Z",
        "officialtime": "00:54:56",
        "race": "lång",
        "runnersclass": "dam",
        "startgroup": 8,
        "runner1": "Maria Normann",
        "runner2": "Sofie  Andreasson"
      },
      {
        "_id": 76,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:15:27.345Z",
        "officialtime": "01:03:42",
        "race": "lång",
        "runnersclass": "dam",
        "startgroup": 8,
        "runner1": "Maria Bergqvist",
        "runner2": "Sanna Dyning"
      },
      {
        "_id": 77,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:57:41.437Z",
        "officialtime": "00:45:56",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 8,
        "runner1": "Martin Thurehag",
        "runner2": "Joakim Tuovinen"
      },
      {
        "_id": 78,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:09:15.652Z",
        "officialtime": "00:57:30",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 8,
        "runner1": "Runa Joelsson",
        "runner2": "Nils Joelsson"
      },
      {
        "_id": 79,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:54:49.377Z",
        "officialtime": "00:43:04",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 8,
        "runner1": "Alice Westling ",
        "runner2": "Rikard  Aronsson "
      },
      {
        "_id": 80,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:01:27.918Z",
        "officialtime": "00:49:42",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 8,
        "runner1": "Emma Axell",
        "runner2": "Philip Hafstrand Wood"
      },
      {
        "_id": 81,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:02:45.261Z",
        "officialtime": "00:49:20",
        "race": "lång",
        "runnersclass": "dam",
        "startgroup": 9,
        "runner1": "Minnéa  Alexandersson",
        "runner2": "Katarina Bergius"
      },
      {
        "_id": 82,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:54:05.803Z",
        "officialtime": "00:40:41",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 9,
        "runner1": "Michael Crona",
        "runner2": "Gabriel  Sturesson"
      },
      {
        "_id": 83,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:05:43.138Z",
        "officialtime": "00:52:18",
        "race": "lång",
        "runnersclass": "dam",
        "startgroup": 9,
        "runner1": "Elsa  Grindebacke ",
        "runner2": "Hanna Grindebacke "
      },
      {
        "_id": 84,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:59:07.510Z",
        "officialtime": "00:45:42",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 9,
        "runner1": "Ingemar Allén",
        "runner2": "Max  Allén"
      },
      {
        "_id": 85,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:01:06.148Z",
        "officialtime": "00:47:41",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 9,
        "runner1": "Johan Lindeberg ",
        "runner2": "Carola  Lindeberg "
      },
      {
        "_id": 86,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:47:29.572Z",
        "officialtime": "00:34:05",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 9,
        "runner1": "Anton  Hägg",
        "runner2": "William Hägg"
      },
      {
        "_id": 87,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:07:39.032Z",
        "officialtime": "00:54:14",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 9,
        "runner1": "Helena  Galle",
        "runner2": "Carl Högström "
      },
      {
        "_id": 88,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:00:37.375Z",
        "officialtime": "00:47:12",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 9,
        "runner1": "Gustav Dalemo",
        "runner2": "Håkan Dalemo"
      },
      {
        "_id": 89,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:02:52.456Z",
        "officialtime": "00:49:27",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 9,
        "runner1": "Jesper Tolinsson",
        "runner2": "Alice Falk"
      },
      {
        "_id": 90,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:00:41.847Z",
        "officialtime": "00:47:17",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 9,
        "runner1": "Andreas Söder",
        "runner2": "Maria Söder"
      },
      {
        "_id": 2,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:50:17.455Z",
        "officialtime": "00:49:57",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 1,
        "runner1": "Alma  Lindkvist",
        "runner2": "Teodor Berger"
      },
      {
        "_id": 92,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:56:50.934Z",
        "officialtime": "00:41:52",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 10,
        "runner1": "Alice Rosenborg",
        "runner2": "Erik Rosenborg"
      },
      {
        "_id": 93,
        "checkin": false,
        "finished": false,
        "finishtime": null,
        "officialtime": null,
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 10,
        "runner1": "Stefan Pettersson",
        "runner2": "Hampus Krook"
      },
      {
        "_id": 94,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:03:19.857Z",
        "officialtime": "00:48:21",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 10,
        "runner1": "Lennart Allinger",
        "runner2": "Magnus Rahm"
      },
      {
        "_id": 95,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:57:47.650Z",
        "officialtime": "00:42:49",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 10,
        "runner1": "Johan Wendelstam",
        "runner2": "Anna Carin  Högquist"
      },
      {
        "_id": 96,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:12:41.791Z",
        "officialtime": "00:57:43",
        "race": "lång",
        "runnersclass": "dam",
        "startgroup": 10,
        "runner1": "Lotta Dahl Petersson",
        "runner2": "Märit Bergendahl"
      },
      {
        "_id": 97,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:04:17.346Z",
        "officialtime": "00:49:19",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 10,
        "runner1": "Anders Eliasson",
        "runner2": "Thomas Alvling"
      },
      {
        "_id": 98,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:13:18.858Z",
        "officialtime": "00:58:20",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 10,
        "runner1": "Johanna  Berg Frisell ",
        "runner2": "Ellen  Berg "
      },
      {
        "_id": 99,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:58:58.748Z",
        "officialtime": "00:44:00",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 10,
        "runner1": "Sandra Lindqvist",
        "runner2": "Johan Hoff"
      },
      {
        "_id": 100,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:50:13.649Z",
        "officialtime": "00:35:15",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 10,
        "runner1": "Ragnar Crona",
        "runner2": "Patrik  Rucinski "
      },
      {
        "_id": 101,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:06:01.634Z",
        "officialtime": "00:49:26",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 11,
        "runner1": "Fredrik Gärdhagen",
        "runner2": "Frida Winzell"
      },
      {
        "_id": 102,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:26:23.112Z",
        "officialtime": "01:09:47",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 11,
        "runner1": "Jeanette Olofsson",
        "runner2": "Cornelis, Fredrik Stoltz"
      },
      {
        "_id": 103,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:00:52.161Z",
        "officialtime": "00:44:16",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 11,
        "runner1": "Filip  Rosen",
        "runner2": "Anders Rosen"
      },
      {
        "_id": 104,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:08:25.256Z",
        "officialtime": "00:51:49",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 11,
        "runner1": "Erik Hallgren",
        "runner2": "Indra  Ankarcrona "
      },
      {
        "_id": 105,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:12:57.891Z",
        "officialtime": "00:56:22",
        "race": "lång",
        "runnersclass": "dam",
        "startgroup": 11,
        "runner1": "Stina Josephson",
        "runner2": "Sofia Tholin"
      },
      {
        "_id": 106,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:22:16.010Z",
        "officialtime": "01:05:40",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 11,
        "runner1": "Maximilian Krantz Högström",
        "runner2": "Nova Krantz Högström"
      },
      {
        "_id": 107,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:01:33.308Z",
        "officialtime": "00:44:57",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 11,
        "runner1": "Carl Hultman",
        "runner2": "Alexander Dentler "
      },
      {
        "_id": 108,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:02:38.133Z",
        "officialtime": "00:46:02",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 11,
        "runner1": "Madeleine  Larsson",
        "runner2": "Per Lewstam "
      },
      {
        "_id": 109,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:54:03.243Z",
        "officialtime": "00:37:27",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 11,
        "runner1": "Nanna Bergendahl ",
        "runner2": "Holger Bergendahl "
      },
      {
        "_id": 110,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:19:41.266Z",
        "officialtime": "01:03:05",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 11,
        "runner1": "Sara  Dahlgren Karlsson",
        "runner2": "Östen Karlsson"
      },
      {
        "_id": 111,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:03:46.080Z",
        "officialtime": "00:45:32",
        "race": "lång",
        "runnersclass": "dam",
        "startgroup": 12,
        "runner1": "Mia Engström ",
        "runner2": "Lotta  Schroderus "
      },
      {
        "_id": 112,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:02:48.057Z",
        "officialtime": "00:44:34",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 12,
        "runner1": "Ellen Engström ",
        "runner2": "Gustav  Schroderus "
      },
      {
        "_id": 113,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:11:53.770Z",
        "officialtime": "00:53:40",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 12,
        "runner1": "Henrik  Svensson",
        "runner2": "Caroline Svensson"
      },
      {
        "_id": 114,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:58:25.662Z",
        "officialtime": "00:40:12",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 12,
        "runner1": "PATRIK FELLDIN",
        "runner2": "IDA ENSTEDT"
      },
      {
        "_id": 115,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:08:06.291Z",
        "officialtime": "00:49:53",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 12,
        "runner1": "Madeleine Bjärne",
        "runner2": "Jonas Sölsnaes"
      },
      {
        "_id": 116,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:53:52.352Z",
        "officialtime": "00:35:39",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 12,
        "runner1": "Jesper Böhn",
        "runner2": "Anton Björck-Teuscher"
      },
      {
        "_id": 117,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:06:10.063Z",
        "officialtime": "00:47:56",
        "race": "lång",
        "runnersclass": "dam",
        "startgroup": 12,
        "runner1": "Charlotta Dovén",
        "runner2": "Madeleine Johansson"
      },
      {
        "_id": 118,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:13:44.182Z",
        "officialtime": "00:55:31",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 12,
        "runner1": "Maria Pekár",
        "runner2": "Ian Pekár"
      },
      {
        "_id": 119,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:06:31.967Z",
        "officialtime": "00:48:18",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 12,
        "runner1": "Hilda Larsson",
        "runner2": "Alfred Larsson"
      },
      {
        "_id": 120,
        "checkin": false,
        "finished": false,
        "finishtime": null,
        "officialtime": null,
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 12,
        "runner1": "Carl-Henrik Somp",
        "runner2": "Andreas Törnblom"
      },
      {
        "_id": 121,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:02:41.826Z",
        "officialtime": "00:42:54",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 13,
        "runner1": "Hugo Holm",
        "runner2": "David Wiklund"
      },
      {
        "_id": 122,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:15:54.254Z",
        "officialtime": "00:56:06",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 13,
        "runner1": "Karin Elam",
        "runner2": "Ludvig  Arwidsson"
      },
      {
        "_id": 123,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:10:41.150Z",
        "officialtime": "00:50:53",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 13,
        "runner1": "David Larsson",
        "runner2": "Gustav  Andersson"
      },
      {
        "_id": 124,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:11:39.971Z",
        "officialtime": "00:51:52",
        "race": "lång",
        "runnersclass": "mixed",
        "startgroup": 13,
        "runner1": "Sebastian Lorentsen",
        "runner2": "Nina Wahlin"
      },
      {
        "_id": 125,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:05:00.631Z",
        "officialtime": "00:45:13",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 13,
        "runner1": "Marcel Escursell",
        "runner2": "Pau Lindgren"
      },
      {
        "_id": 126,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:07:28.445Z",
        "officialtime": "00:47:40",
        "race": "lång",
        "runnersclass": "herr",
        "startgroup": 13,
        "runner1": "Nils Grindebacke",
        "runner2": "Andreas Grindebacke"
      },
      {
        "_id": 301,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:19:50.056Z",
        "officialtime": "00:19:49",
        "race": "mellan",
        "runnersclass": "herr",
        "startgroup": 1,
        "runner1": "Kristian Holmquist",
        "runner2": "Hugo Holmquist"
      },
      {
        "_id": 302,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:23:18.518Z",
        "officialtime": "00:23:17",
        "race": "mellan",
        "runnersclass": "mixed",
        "startgroup": 1,
        "runner1": "Miranda Pettersson ",
        "runner2": "Kevin  Tendahl"
      },
      {
        "_id": 303,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:24:17.384Z",
        "officialtime": "00:24:16",
        "race": "mellan",
        "runnersclass": "dam",
        "startgroup": 1,
        "runner1": "Vera Addinsall",
        "runner2": "Billie Björk Jortay"
      },
      {
        "_id": 304,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:31:35.050Z",
        "officialtime": "00:31:34",
        "race": "mellan",
        "runnersclass": "dam",
        "startgroup": 1,
        "runner1": "Nova Addinsall",
        "runner2": "Malin  Björk"
      },
      {
        "_id": 305,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:24:33.204Z",
        "officialtime": "00:24:32",
        "race": "mellan",
        "runnersclass": "mixed",
        "startgroup": 1,
        "runner1": "Esther Kyllerman",
        "runner2": "Hampus Krook"
      },
      {
        "_id": 306,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:20:18.988Z",
        "officialtime": "00:20:18",
        "race": "mellan",
        "runnersclass": "mixed",
        "startgroup": 1,
        "runner1": "Lena Fransson ",
        "runner2": "Arvid  Fransson "
      },
      {
        "_id": 307,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:19:52.759Z",
        "officialtime": "00:19:51",
        "race": "mellan",
        "runnersclass": "dam",
        "startgroup": 1,
        "runner1": "Frida Wiigh",
        "runner2": "Karin Wiigh"
      },
      {
        "_id": 308,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:18:49.280Z",
        "officialtime": "00:18:48",
        "race": "mellan",
        "runnersclass": "dam",
        "startgroup": 1,
        "runner1": "Ruth Birging",
        "runner2": "Julia Lembäck"
      },
      {
        "_id": 309,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:19:31.699Z",
        "officialtime": "00:19:30",
        "race": "mellan",
        "runnersclass": "herr",
        "startgroup": 1,
        "runner1": "Olof  Lembäck Larsson",
        "runner2": "William  Lembäck"
      },
      {
        "_id": 310,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:24:48.185Z",
        "officialtime": "00:24:47",
        "race": "mellan",
        "runnersclass": "mixed",
        "startgroup": 1,
        "runner1": "William  Enlund ",
        "runner2": "Gustav  Enlund, Jenny Enlund"
      },
      {
        "_id": 311,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:41:06.827Z",
        "officialtime": "00:39:06",
        "race": "mellan",
        "runnersclass": "mixed",
        "startgroup": 2,
        "runner1": "Karin Björkegren",
        "runner2": "Gustav Sandgren "
      },
      {
        "_id": 312,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:23:45.662Z",
        "officialtime": "00:21:45",
        "race": "mellan",
        "runnersclass": "dam",
        "startgroup": 2,
        "runner1": "Ingrid  Berlin",
        "runner2": "Stina-Cajsa  Berlin "
      },
      {
        "_id": 313,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:29:37.820Z",
        "officialtime": "00:27:37",
        "race": "mellan",
        "runnersclass": "herr",
        "startgroup": 2,
        "runner1": "Mattias Klysing",
        "runner2": "Axel Klysing "
      },
      {
        "_id": 314,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:24:29.487Z",
        "officialtime": "00:22:29",
        "race": "mellan",
        "runnersclass": "dam",
        "startgroup": 2,
        "runner1": "Maja Klysing",
        "runner2": "Lina  Klysing"
      },
      {
        "_id": 315,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:26:42.420Z",
        "officialtime": "00:24:42",
        "race": "mellan",
        "runnersclass": "dam",
        "startgroup": 2,
        "runner1": "Cecilia Lindström",
        "runner2": "Karin Hellström"
      },
      {
        "_id": 316,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:24:51.409Z",
        "officialtime": "00:22:51",
        "race": "mellan",
        "runnersclass": "mixed",
        "startgroup": 2,
        "runner1": "Anton Söderlund",
        "runner2": "Ellen Wennebrink"
      },
      {
        "_id": 317,
        "checkin": false,
        "finished": false,
        "finishtime": null,
        "officialtime": null,
        "race": "mellan",
        "runnersclass": "dam",
        "startgroup": 2,
        "runner1": "My Yousofi",
        "runner2": "Minoo Yousofi"
      },
      {
        "_id": 318,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:27:25.828Z",
        "officialtime": "00:25:25",
        "race": "mellan",
        "runnersclass": "herr",
        "startgroup": 2,
        "runner1": "Axel Joelsson",
        "runner2": "Sebastian Persson"
      },
      {
        "_id": 319,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:27:09.278Z",
        "officialtime": "00:25:09",
        "race": "mellan",
        "runnersclass": "herr",
        "startgroup": 2,
        "runner1": "Markus Segerlind",
        "runner2": "Harry Segerlind"
      },
      {
        "_id": 320,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:28:34.184Z",
        "officialtime": "00:26:34",
        "race": "mellan",
        "runnersclass": "herr",
        "startgroup": 2,
        "runner1": "Martin Bergius",
        "runner2": "Emil Bergius"
      },
      {
        "_id": 321,
        "checkin": false,
        "finished": false,
        "finishtime": null,
        "officialtime": null,
        "race": "mellan",
        "runnersclass": "dam",
        "startgroup": 3,
        "runner1": "Maja  Klysing",
        "runner2": "Molly Persson"
      },
      {
        "_id": 322,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:34:05.696Z",
        "officialtime": "00:30:13",
        "race": "mellan",
        "runnersclass": "mixed",
        "startgroup": 3,
        "runner1": "Valter  Kullberg",
        "runner2": "Helena Kullberg"
      },
      {
        "_id": 323,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:27:34.277Z",
        "officialtime": "00:23:42",
        "race": "mellan",
        "runnersclass": "herr",
        "startgroup": 3,
        "runner1": "Christian Kyllerman",
        "runner2": "Philip Kyllerman"
      },
      {
        "_id": 324,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:29:57.935Z",
        "officialtime": "00:26:05",
        "race": "mellan",
        "runnersclass": "dam",
        "startgroup": 3,
        "runner1": "Ella  Bråthen Tengelin",
        "runner2": "Lo  Svärd"
      },
      {
        "_id": 325,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:29:46.180Z",
        "officialtime": "00:25:54",
        "race": "mellan",
        "runnersclass": "dam",
        "startgroup": 3,
        "runner1": "Stina  Kronberg",
        "runner2": "Alexandra Kronberg"
      },
      {
        "_id": 326,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:25:59.765Z",
        "officialtime": "00:22:07",
        "race": "mellan",
        "runnersclass": "herr",
        "startgroup": 3,
        "runner1": "Niklas Berg Frisell",
        "runner2": "Christian  Throlin"
      },
      {
        "_id": 327,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:23:52.746Z",
        "officialtime": "00:20:00",
        "race": "mellan",
        "runnersclass": "herr",
        "startgroup": 3,
        "runner1": "Carl Landström",
        "runner2": "Erik Holmberg"
      },
      {
        "_id": 328,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:25:22.994Z",
        "officialtime": "00:21:30",
        "race": "mellan",
        "runnersclass": "mixed",
        "startgroup": 3,
        "runner1": "Vida Lindkvist",
        "runner2": "Olle Lindkvist"
      },
      {
        "_id": 329,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:31:09.538Z",
        "officialtime": "00:27:17",
        "race": "mellan",
        "runnersclass": "dam",
        "startgroup": 3,
        "runner1": "Charlotte  Alring",
        "runner2": "Ellen Alring "
      },
      {
        "_id": 330,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:22:40.920Z",
        "officialtime": "00:18:48",
        "race": "mellan",
        "runnersclass": "mixed",
        "startgroup": 3,
        "runner1": "Olle  Engström",
        "runner2": "Märta  Johansson "
      },
      {
        "_id": 331,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:31:37.153Z",
        "officialtime": "00:25:42",
        "race": "mellan",
        "runnersclass": "dam",
        "startgroup": 4,
        "runner1": "Susanne  Karjalainen ",
        "runner2": "Edith  Karjalainen "
      },
      {
        "_id": 332,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:30:46.826Z",
        "officialtime": "00:24:52",
        "race": "mellan",
        "runnersclass": "herr",
        "startgroup": 4,
        "runner1": "Kimchi  Widmark",
        "runner2": "Isak Widmark"
      },
      {
        "_id": 333,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:32:03.748Z",
        "officialtime": "00:26:09",
        "race": "mellan",
        "runnersclass": "mixed",
        "startgroup": 4,
        "runner1": "Michaela Voeler",
        "runner2": "Thomas Voeler"
      },
      {
        "_id": 334,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:31:47.629Z",
        "officialtime": "00:25:53",
        "race": "mellan",
        "runnersclass": "mixed",
        "startgroup": 4,
        "runner1": "Ida Wilhelmsson",
        "runner2": "Wilhelm Wilhelmsson"
      },
      {
        "_id": 335,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:31:35.603Z",
        "officialtime": "00:25:41",
        "race": "mellan",
        "runnersclass": "mixed",
        "startgroup": 4,
        "runner1": "Alexander Lidmejer",
        "runner2": "Svea  Lidmejer"
      },
      {
        "_id": 336,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:29:23.781Z",
        "officialtime": "00:23:29",
        "race": "mellan",
        "runnersclass": "dam",
        "startgroup": 4,
        "runner1": "Klara Sarv",
        "runner2": "Selma Sarv"
      },
      {
        "_id": 337,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:32:55.566Z",
        "officialtime": "00:27:01",
        "race": "mellan",
        "runnersclass": "mixed",
        "startgroup": 4,
        "runner1": "Niklas Carlsson",
        "runner2": "Kamilla Carlsson"
      },
      {
        "_id": 338,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:31:09.887Z",
        "officialtime": "00:25:15",
        "race": "mellan",
        "runnersclass": "mixed",
        "startgroup": 4,
        "runner1": "Emil Pettersson",
        "runner2": "Therese Winell"
      },
      {
        "_id": 339,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:28:01.102Z",
        "officialtime": "00:22:06",
        "race": "mellan",
        "runnersclass": "herr",
        "startgroup": 4,
        "runner1": "Henry Dovén",
        "runner2": "Jonathan Dovén"
      },
      {
        "_id": 340,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:27:21.107Z",
        "officialtime": "00:21:26",
        "race": "mellan",
        "runnersclass": "herr",
        "startgroup": 4,
        "runner1": "Hugo Dovén",
        "runner2": "Leandro Sölsnaes"
      },
      {
        "_id": 341,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:31:13.654Z",
        "officialtime": "00:23:11",
        "race": "mellan",
        "runnersclass": "mixed",
        "startgroup": 5,
        "runner1": "Erik Karlsson",
        "runner2": "Elisabeth  Karlsson"
      },
      {
        "_id": 342,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:34:08.932Z",
        "officialtime": "00:26:06",
        "race": "mellan",
        "runnersclass": "mixed",
        "startgroup": 5,
        "runner1": "Daniel Bjärne",
        "runner2": "Celia  Bjärne "
      },
      {
        "_id": 343,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:40:08.847Z",
        "officialtime": "00:32:06",
        "race": "mellan",
        "runnersclass": "herr",
        "startgroup": 5,
        "runner1": "Claes Westling",
        "runner2": "Kim Bergqvist "
      },
      {
        "_id": 344,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:30:47.823Z",
        "officialtime": "00:22:45",
        "race": "mellan",
        "runnersclass": "dam",
        "startgroup": 5,
        "runner1": "Sophie  Hegestam ",
        "runner2": "Linnéa   Hofvander Hegestam"
      },
      {
        "_id": 345,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:31:48.632Z",
        "officialtime": "00:23:46",
        "race": "mellan",
        "runnersclass": "dam",
        "startgroup": 5,
        "runner1": "Ebba Hegestam ",
        "runner2": "Lovisa Hofvander Hegestam "
      },
      {
        "_id": 346,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:28:29.733Z",
        "officialtime": "00:20:27",
        "race": "mellan",
        "runnersclass": "herr",
        "startgroup": 5,
        "runner1": "Hugo Somp",
        "runner2": "Axel Törnblom"
      },
      {
        "_id": 347,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:32:23.787Z",
        "officialtime": "00:24:21",
        "race": "mellan",
        "runnersclass": "herr",
        "startgroup": 5,
        "runner1": "August Somp",
        "runner2": "Ture Törnblom"
      },
      {
        "_id": 348,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:42:10.913Z",
        "officialtime": "00:34:08",
        "race": "mellan",
        "runnersclass": "mixed",
        "startgroup": 5,
        "runner1": "Christina Jensen",
        "runner2": "Joar Jensen"
      },
      {
        "_id": 349,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:27:36.366Z",
        "officialtime": "00:19:34",
        "race": "mellan",
        "runnersclass": "mixed",
        "startgroup": 5,
        "runner1": "Vilgot Johnard",
        "runner2": "Emma  Ardbo Johnard"
      },
      {
        "_id": 350,
        "runnersclass": "mixed",
        "race": "mellan",
        "startgroup": 5,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:29:43.887Z",
        "officialtime": "00:21:41",
        "runner1": "Hildegard Granholm",
        "runner2": "Andreas Granholm"
      },
      {
        "_id": 127,
        "runnersclass": "mixed",
        "race": "lång",
        "startgroup": 13,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:08:42.947Z",
        "officialtime": "00:48:55",
        "runner1": "Edgar Hejdenberg",
        "runner2": "Christina Nordlind Hejdenberg"
      },
      {
        "_id": 351,
        "runnersclass": "herr",
        "race": "mellan",
        "startgroup": 5,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:31:28.257Z",
        "officialtime": "00:23:26",
        "fisishtime": null,
        "runner1": "Valter Thörner ",
        "runner2": "Ted Thörner"
      },
      {
        "_id": 352,
        "runnersclass": "herr",
        "race": "mellan",
        "startgroup": 5,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:29:40.160Z",
        "officialtime": "00:21:38",
        "runner1": "Björn Lindahl",
        "runner2": "Hjalmar Thörner"
      },
      {
        "_id": 1,
        "runnersclass": "herr",
        "race": "lång",
        "startgroup": 1,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:41:03.484Z",
        "officialtime": "00:40:43",
        "fisishtime": null,
        "runner1": "Stefan Pettersson",
        "runner2": "Hampus Krook"
      },
      {
        "_id": 353,
        "runnersclass": "dam",
        "race": "mellan",
        "startgroup": 5,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:36:07.669Z",
        "officialtime": "00:28:05",
        "runner1": "Helene Olviken",
        "runner2": "Ingela Ahlstrand"
      },
      {
        "_id": 354,
        "runnersclass": "herr",
        "race": "mellan",
        "startgroup": 5,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:32:22.865Z",
        "officialtime": "00:24:20",
        "runner1": "Simon Lefler",
        "runner2": "Emil Eckerstein"
      },
      {
        "_id": 128,
        "runnersclass": "herr",
        "race": "lång",
        "startgroup": 13,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:20:44.115Z",
        "officialtime": "01:00:56",
        "runner1": "Theo Rendahl",
        "runner2": "Alexander Borgquist"
      },
      {
        "_id": 129,
        "runnersclass": "herr",
        "race": "lång",
        "startgroup": 13,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:05:53.290Z",
        "officialtime": "00:46:05",
        "runner1": "Robin Kallström",
        "runner2": "Nils Österström"
      },
      {
        "_id": 130,
        "runnersclass": "dam",
        "race": "lång",
        "startgroup": 13,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T12:20:50.692Z",
        "officialtime": "01:01:03",
        "runner1": "Ulrika Nordberg ",
        "runner2": "Elisabet Dahllöf"
      },
      {
        "_id": 131,
        "runnersclass": "herr",
        "race": "lång",
        "startgroup": 1,
        "checkin": true,
        "finished": true,
        "finishtime": "2024-07-13T11:43:51.897Z",
        "officialtime": "00:43:31",
        "fisishtime": null,
        "runner1": "Charlie Bagge",
        "runner2": "Samuel Dahlkvist"
      }
];
export default runners;
